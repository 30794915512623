import React, {useEffect, useState} from 'react';
import './App.css';
import Homepage from "./pages/Homepage";
import DesktopNav from "./components/DesktopNav";
import MobileNav from "./components/MobileNav";
import Footer from "./components/Footer";
import { Route, Routes, useNavigate } from "react-router-dom";
import {auth} from "./firebase";
import {User} from "firebase/auth";
import DetailPage from "./pages/DetailPage";
import AddEditBlog from "./pages/AddEditBlog";
import NotFound from "./pages/NotFound";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./ProtectedRoute";


function App() {
    const [user, setUser] = useState<null | User>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    useEffect(() => {
        auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                localStorage.setItem('user', JSON.stringify(authUser));
            } else {
                setUser(null);
                localStorage.removeItem('user');
            }
        });
    }, []);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
    <div className="App">
        {isMobile ? <MobileNav /> : <DesktopNav />}

        <Routes>
            <Route path="/" element={<Homepage user={user}/>}/>
            <Route path="/:title" element={<DetailPage/>}/>
            <Route path="/create" element={
                <ProtectedRoute>
                    <AddEditBlog key="new" user={user} />
                </ProtectedRoute>
            } />
            <Route path="/update/:id" element={
                <ProtectedRoute>
                    <AddEditBlog key="exist" user={user} />
                </ProtectedRoute>
            } />
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>

        <Footer />
    </div>
  );
}

export default App;


/*
*
* zachytit vsechyn volani setForm
* */
