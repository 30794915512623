import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import "../styles/DesktopNav.css";
import "../styles/MobileNav.css";

import logo2 from "../assets/haaro_logo_light.png";

class MobileNav extends Component {

    closeOverlay = (): void => {
        const overlay = document.querySelector('.overlay') as HTMLElement;
        const contain = document.querySelector('.contain') as HTMLElement;

        if (overlay && contain && overlay.classList.contains('open')) {
            overlay.classList.remove('open');
            contain.classList.remove('change');
        }
    }

    componentDidMount() {
        const toggleButton = document.querySelector('.navbar-buttons');
        if (toggleButton) {
            toggleButton.addEventListener('click', this.toggleClasses);
        }

        const navLinks = document.querySelectorAll('.nav-link');
        navLinks.forEach(link => {
            link.addEventListener('click', this.closeOverlay);
        });
    }

    componentWillUnmount() {
        const toggleButton = document.querySelector('.navbar-buttons');
        if (toggleButton) {
            toggleButton.removeEventListener('click', this.toggleClasses);
        }

        const navLinks = document.querySelectorAll('.nav-link');
        navLinks.forEach(link => {
            link.removeEventListener('click', this.closeOverlay);
        });
    }

    toggleClasses = () => {
        const overlay = document.querySelector('.overlay');
        const contain = document.querySelector('.contain');

        if (overlay && contain) {
            overlay.classList.toggle('open');
            contain.classList.toggle('change');
        }
    }

    render() {
        return (
                <header className="container">
                    <p>HAARO-BLOG</p>
                    <div>
                        <nav id="mobile-navbar" className="mobile-nav-container">
                            <div className="navbar-buttons">
                                <button className="contain open-menu">
                                    <span className="bar1"></span>
                                    <span className="bar2"></span>
                                    <span className="bar3"></span>
                                </button>
                            </div>
                        </nav>
                        <nav className="overlay">
                            <div className="overlay-menu">
                                <ul>
                                    <li><a href="https://haarosalon.cz/index.html" className="logo2"><img id="logo2"
                                                                                                          src={logo2}
                                                                                                          alt="Haaro Naturo logo"/></a>
                                    </li>
                                    <li><a target="_blank" href="https://www.haaro-naturo.cz/" data-link="e-shop"
                                           className="nav-link"><span>e-shop</span></a></li>
                                    <li><Link to="/" data-link="blog" className="nav-link"><span>blog</span></Link></li>
                                    <li><a href="https://haarosalon.cz/index.html" data-link="úvod"
                                           className="nav-link"><span>úvod</span></a></li>
                                    <li><a href="https://haarosalon.cz/pages/oHaaro.html" data-link="o haaro"
                                           className="nav-link"><span>o haaro</span></a></li>
                                    <li><a href="https://haarosalon.cz/pages/vyroba.html" data-link="výroba"
                                           className="nav-link"><span>výroba</span></a>
                                    </li>
                                    <li><a href="https://haarosalon.cz/pages/kadernictvi.html" data-link="kadeřnictví"
                                           className="nav-link"><span>kadeřnictví</span></a></li>
                                    <li><a href="https://haarosalon.cz/pages/kontakt.html" data-link="kontakt"
                                           className="nav-link"><span>kontakt</span></a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </header>
        );
    }
}

export default MobileNav;
