// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

/*
New service account HMAC key
admin-1@blogova-aplikace.iam.gserviceaccount.com
Access key
GOOG1EQASLIEYKSDR43QQGLHBRSR5VFSKCBSAPNAUXFFZ34EMPHIRR656ASXC
Secret
hsCOqgNTU7099AB9CcUW2sPwb/amcjow2coIvTu0
 */
const firebaseConfig = {
    apiKey: "AIzaSyA7W5_fTU_vRjv3YQCm2pdVKBFCOM5tjuQ",
    authDomain: "blogova-aplikace.firebaseapp.com",
    projectId: "blogova-aplikace",
    storageBucket: "blogova-aplikace.appspot.com",
    messagingSenderId: "1000024448629",
    appId: "1:1000024448629:web:0d92d4eb40868e424ee23c",
    measurementId: "G-632X77F0YP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
