import React, {Component} from 'react';
import {Link} from "react-router-dom";
import ProtectedBtnDelete from "./ProtectedBtnDelete";
import ProtectedBtnUpdate from "./ProtectedBtnUpdate";
import "../styles/DesktopNav.css";

interface Blog {
    id: string;
    imgUrl: string;
    title: string;
    description: string;
    category: string[];
}

interface BlogSectionProps {
    blogs: Blog[],
    user: any,
    handleDelete: (id: string) => Promise<void>;
}

class BlogSection extends Component<BlogSectionProps> {
    render() {
        const { blogs, user, handleDelete } = this.props;

        const toUrlFormat = (title: string) => {
            const normalized = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            return normalized.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
        };

        const shortedHtml = (html: string, maxLength: number): string => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            let textContent = doc.body.textContent || "";

            if (textContent.length <= maxLength) {
                return html;
            }

            textContent = textContent.slice(0, maxLength) + "...";
            doc.body.textContent = textContent;

            return doc.body.innerHTML;
        };

        return (
            <div className="BlogSection">
                <section>
                    {blogs?.map((item) => (
                        <div key={item.id} className={"BlogItem"}>
                            <Link to={`/${toUrlFormat(item.title)}`} state={{ id: item.id }}>
                                <div className={"BlogLink"}>
                                    <img src={item.imgUrl} alt={item.title}/>
                                    <p className={"category-item"}>
                                        {item.category && item.category.length > 1
                                            ? item.category.map((cat, index) => (
                                                <React.Fragment key={index}>
                                                    {index > 0 && ' '}
                                                    <span className="category-span">{cat}</span>
                                                </React.Fragment>
                                            ))
                                            : 'Žádné kategorie'
                                        }
                                    </p>
                                    <h2>{item.title}</h2>
                                    <div className={"perex"}
                                         dangerouslySetInnerHTML={{__html: shortedHtml(item.description, 300) || ""}}></div>
                                </div>
                            </Link>
                            <Link to={`/detail/${toUrlFormat(item.title)}`} state={{ id: item.id }} data-link="chci číst dál" className="nav-link">
                                <span className={"read-more"}>chci číst dál</span>
                            </Link>
                            <ProtectedBtnDelete onClick={() => handleDelete(item.id)}>smazat</ProtectedBtnDelete>
                            <Link to={`/update/${item.id}`}>
                                <ProtectedBtnUpdate>upravit</ProtectedBtnUpdate>
                            </Link>
                        </div>
                    ))}
                </section>
            </div>
        );
    }
}

export default BlogSection;
