import React, { ReactNode } from 'react';

interface ProtectedButtonProps {
    onClick: () => void;
    children: ReactNode;
}

const ProtectedBtnDelete: React.FC<ProtectedButtonProps> = ({ onClick, children }) => {
    const userJson = localStorage.getItem('user');
    const user = userJson ? JSON.parse(userJson) : null;

    if (!user) return null;

    return <button onClick={onClick}>{children}</button>;
};

export default ProtectedBtnDelete;
