import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import logo from "../assets/haaro_logo_black.png";
import Homepage from "../pages/Homepage";
import "../styles/DesktopNav.css";

export class DesktopNav extends Component {
    render() {
        return (
                <header>
                    <nav id="navbar" className="nav-container">
                        <p data-link="HAARO-BLOG"><span>HAARO-BLOG</span></p>
                        <a href="https://haarosalon.cz/index.html" className="logo"><img id="logo" src={logo}
                                                                                     alt="Haaro Naturo logo"/></a>
                        <span id="mockup"></span>
                      {/*  <a href="#" className="nav-link"></a>*/}
                        <ul>
                            <li>
                                <a target="_blank" href="https://www.haaro-naturo.cz/" data-link="e-shop"
                                   className="nav-link"><span>e-shop</span></a>
                            </li>
                            <li>
                                <Link to="/" data-link="blog" className="nav-link"><span>blog</span></Link>
                            </li>
                            <li>
                                <a href="https://haarosalon.cz/index.html" data-link="úvod"
                                   className="nav-link"><span>úvod</span></a>
                            </li>
                            <li>
                                <a href="https://haarosalon.cz/pages/oHaaro.html" data-link="o haaro"
                                   className="nav-link"><span>o haaro</span></a>
                            </li>
                            <li>
                                <a href="https://haarosalon.cz/pages/vyroba.html" data-link="výroba"
                                   className="nav-link"><span>výroba</span></a>
                            </li>
                            <li>
                                <a href="https://haarosalon.cz/pages/kadernictvi.html" data-link="kadeřnictví"
                                   className="nav-link"><span>kadeřnictví</span></a>
                            </li>
                            <li>
                                <a href="https://haarosalon.cz/pages/kontakt.html" data-link="kontakt"
                                   className="nav-link"><span>kontakt</span></a>
                            </li>
                        </ul>
                    </nav>
                </header>
        );
    }
}

export default DesktopNav;
