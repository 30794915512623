import React, {Component} from 'react';
import {Link} from "react-router-dom";

import "../styles/Footer.css";

import igLogo from "../assets/ig.png";
import fbLogo from "../assets/fb.png";

class Footer extends Component {
    render() {
        return (
                <footer id="footer-container" className="section" data-bgcolor="#687e83">
                    <div className="footer-container">

                        <div className="slogan">
                            <p>Haaro Naturo ~ naroste i vám</p>
                        </div>

                        <div className="slogan-mobile">
                            <p>Haaro Naturo<br/>~<br/>naroste i vám</p>
                        </div>

                        <div className="footer-text">
                            <div className="col column1">
                                <h2>Kontaktujte nás</h2>
                                <ul>
                                    <li><a href="tel:+420 775 374 065" className="nav-link"
                                           data-link="+420 775 374 065"><span>+420 775 374 065</span></a></li>
                                    <li><a href="mailto:salon@haaro-naturo.cz" className="nav-link"
                                           data-link="salon@haaro-naturo.cz"><span>salon@haaro-naturo.cz</span></a>
                                    </li>

                                    <li><a target="_blank"
                                           href="https://www.instagram.com/haaro_naturo/"><img src={igLogo}
                                                                                               alt="Logo instagramu"/></a>
                                        <a target="_blank"
                                           href="https://www.facebook.com/HaaroNaturo"><img src={fbLogo}
                                                                                            alt="Logo facebooku"/></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col column2">
                                <h2>Menu</h2>
                                <ul>
                                    <li><a target="_blank" data-link="e-shop" className="nav-link"
                                           href="https://www.haaro-naturo.cz/"><span>e-shop</span></a>
                                    </li>
                                    <li><a target="_blank" data-link="vlasová kosmetika" className="nav-link"
                                           href="https://www.haaro-naturo.cz/vlasova-kosmetika/"><span>vlasová kosmetika</span></a>
                                    </li>
                                    <li><a target="_blank" data-link="přírodní barvy na vlasy" className="nav-link"
                                           href="https://www.haaro-naturo.cz/prirodni-barvy-na-vlasy/"><span>přírodní barvy na vlasy</span></a>
                                    </li>
                                    <li><a href="https://haarosalon.cz/pages/kadernictvi.html" data-link="kadeřnictví"
                                           className="nav-link"><span>kadeřnictví</span></a></li>
                                    <li><a href="https://haarosalon.cz/pages/oHaaro.html" data-link="o haaro"
                                           className="nav-link"><span>o haaro</span></a></li>
                                    <li><a target="_blank" data-link="online poradna" className="nav-link"
                                           href="https://www.haaro-edu.cz/"><span>online poradna</span></a></li>
                                </ul>

                            </div>
                            <div className="col column3">
                                <h2>Více o Haaro</h2>
                                <ul>
                                    <li><a target="_blank" data-link="blog" className="nav-link"
                                           href="https://www.haaro-naturo.cz/blog/"><span>blog</span></a>
                                    </li>
                                    <li><a href="https://haarosalon.cz/pages/vyroba.html" data-link="výroba"
                                           className="nav-link"><span>výroba</span></a>
                                    </li>
                                    <li><a href="https://haarosalon.cz/pages/kontakt.html" data-link="kontakt"
                                           className="nav-link"><span>kontakt</span></a></li>
                                    <li><a target="_blank" data-link="věrnostní klub" className="nav-link"
                                           href="https://www.haaro-naturo.cz/vernostni-program/"><span>věrnostní klub</span></a>
                                    </li>
                                    <li><a href="https://haarosalon.cz/pages/kadernictvi.html" data-link="kadeřnictví"
                                           className="nav-link"><span>kadeřnictví</span></a></li>
                                    <li><a target="_blank" data-link="pomáháme" className="nav-link"
                                           href="https://www.haaro-naturo.cz/pomahame/"><span>pomáháme</span></a>
                                    </li>
                                </ul>

                            </div>
                            <div className="col column4">
                                <h2>O nákupu</h2>
                                <ul>
                                <li><a target="_blank" data-link="obchodní podmínky" className="nav-link"
                                           href="https://www.haaro-naturo.cz/obchodni-podminky/"><span>obchodní podmínky</span></a>
                                    </li>
                                    <li><a target="_blank" data-link="podmínky ochrany osobních údajů"
                                           className="nav-link"
                                           href="https://www.haaro-naturo.cz/podminky-ochrany-osobnich-udaju/"><span>podmínky ochrany osobních údajů</span></a>
                                    </li>
                                    <li><a target="_blank" data-link="přeprava a platba" className="nav-link"
                                           href="https://www.haaro-naturo.cz/preprava-a-platba/"><span>přeprava a platba</span></a>
                                    </li>
                                    <li><a target="_blank" data-link="velkoobchod" className="nav-link"
                                           href="https://www.haaro-naturo.cz/velkoobchod-2/"><span>velkoobchod</span></a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div id="footer-copy" className="footer-copy">
                            <p><Link to={"/login"}>©</Link> 2024 Haaro Naturo <Link to={"/create"}>s.r.o</Link></p>
                            <p>Created by <a target="_blank" href="#">Julie Sanetrníková</a></p>
                        </div>
                    </div>
                </footer>
        );
    }
}

export default Footer;
