import SamponNaObjem from "../assets/products/sampon-na-objem-tuhy.jpg";
import SamponNaUhlazeni from "../assets/products/tuhy-sampon-na-uhlazeni.jpeg";
import SamponNaMastneVlasy from "../assets/products/tuhy-sampon-na-mastne-vlasy.jpeg";
import SamponKonopny from "../assets/products/konopny-tuhy-sampon.jpeg";
import SamponNaLupy from "../assets/products/tuhy-sampon-na-lupy.jpeg";
import SamponMesickovy from "../assets/products/tuhy-mesickovy-sampon.jpeg";
import SamponKavovy from "../assets/products/tuhy-kavovy-sampon.jpeg";
import SamponPredBarvenim from "../assets/products/tuhy-sampon-pred-barvenim.jpeg";
import AntistatickySprej from "../assets/products/antistaticky-sprej.jpeg";
import LeaveInKondicioner from "../assets/products/leave-in-kondicioner.jpeg";
import SuchyVlasovyOlej from "../assets/products/suchy-vlasovy-olej.jpeg";
import AloeVeraGel from "../assets/products/aloe-vera-gel.jpeg";
import LehkyKondicioner from "../assets/products/lehky-kondicioner-na-jemne-vlasy.jpeg";
import TuhyKondicionerNaJemneVlasy from "../assets/products/tuhy-kondicioner-na-jemne-vlasy.jpeg";
import VyzivujiciKondicioner from "../assets/products/vyzivujici-kondicioner.jpeg";
import TuhyKondicionerProSucheASilneVlasy from "../assets/products/tuhy-kondicioner-na-suche-vlasy.jpeg";
import KavovyKondicioner from "../assets/products/kavovy-kondicioner.jpeg";
import SuchySamponBlond from "../assets/products/suchy-sampon-blond.jpeg";
import SuchySamponHnedy from "../assets/products/suchy-sampon-hnedy.jpeg";
import SuchySamponMedeny from "../assets/products/suchy-sampon-medeny.jpeg";
import SuchySamponTmaveHnedy from "../assets/products/suchy-sampon-tmave-hnedy.jpeg";
import NaplnSuchySamponBlond from "../assets/products/suchy-sampon-nahradni-napln-blond.jpeg";
import NaplnSuchySamponHnedy from "../assets/products/suchy-sampon-nahradni-napln-hnedy.jpeg";
import NaplnSuchySamponMedeny from "../assets/products/suchy-sampon-nahradni-napln-medena.jpeg";
import NaplnSuchySamponTmaveHnedy from "../assets/products/suchy-sampon-nahradni-napln-tmave-hnedy.jpeg";
import NaplnSuchySamponVsechnyBarvy from "../assets/products/suchy-sampon-nahradni-napln-vsechny-barvy.jpeg";
import BarvaNaMiru from "../assets/products/barva-na-miru.png";
import KonzultaceBarvaNaVlasy from "../assets/products/konzultace.jpg";
import VzorekBarvy from "../assets/products/vzorek-barvy.png";
import MydlenkaBorovicova from "../assets/products/mydlenka-borovicova.jpeg";
import MydlenkaKeramicka from "../assets/products/mydlenka-keramicka.jpeg";
import SitkaNaMydlo from "../assets/products/sitka-na-mydlo.jpeg";
import Workshopy from "../assets/products/workshopy.jpeg";
import CassiaKhadi from "../assets/products/cassia-khadi.png";
import ZlatyPrelivKhadi from "../assets/products/zlaty-preliv-khadi.png";
import SvetleBlondKhadi from "../assets/products/svetla-blond-khadi.png";
import SredneBlondKhadi from "../assets/products/stredni-blond-khadi.png";
import SlunecniBlondKhadi from "../assets/products/slunecni-blond-khadi.png";
import MedenaKhadi from "../assets/products/medena-khadi.png";
import HennaAmlaJatrophaKhadi from "../assets/products/henna-amla-jatropha-khadi.png";
import HennaKhadi from "../assets/products/henna-khadi.png";
import TmavaBlondKhadi from "../assets/products/tmava-blond-khadi.png";
import ZlataHnedaKhadi from "../assets/products/zlata-hneda-khadi.png";
import SvetleHnedaKhadi from "../assets/products/svetle-hneda-khadi.png";
import PopelaveHnedaKhadi from "../assets/products/popelave-hneda-khadi.png";
import OriskoveHnedaKhadi from "../assets/products/oriskove-hneda-khadi.png";
import StredniHnedaKhadi from "../assets/products/stredne-hneda-khadi.png";
import TmaveHnedaKhadi from "../assets/products/tmave-hneda-khadi.png";
import CernaKhadi from "../assets/products/cerna-khadi.png";
import IndigoKhadi from "../assets/products/indigo-khadi.png";
import VlasoveTonikumKhadi from "../assets/products/vlasove-tonikum-stylingovy-sprej-khadi.jpeg";
import OlejNaVlasyAlmaKhadi from "../assets/products/olej-amla-khadi.png";
import OlejNaVlasyColorCareKhadi from "../assets/products/khadi-olej-color-care.png";
import OlejNaVlasyNeemKhadi from "../assets/products/neem-olej-khadi.png";
import OlejNaVlasyRuzeKhadi from "../assets/products/olej-ruze-khadi.png";
import OlejNaVlasyVitalitaKhadi from "../assets/products/olej-khadi-vitalita.png";
import GelNaVlasyOliebe from "../assets/products/styling-gel-oliebe.png";
import AlmaKhadi from "../assets/products/amla-byliny-khadi.png";
import CisteniUhlimKhadi from "../assets/products/aktivni-uhli-khadi.png";
import ShikakaiKhadi from "../assets/products/shikakai-khadi.png";
import SmesBylinKhadi from "../assets/products/smes-bylin-khadi.png";
import RucnikBezovy from "../assets/products/rucnik-bezovy.jpeg";
import RucnikModry from "../assets/products/rucnik-modry.jpeg";
import RucnikSedy from "../assets/products/rucnek-sedy.jpeg";
import RucnikFialovy from "../assets/products/rucnik-fialovy.jpeg";
import RucnikCerny from "../assets/products/rucnik-cerny.jpeg";
import RucnikRuzovy from "../assets/products/rucnik-ruzovy.jpeg";
import SkripceRuzovy from "../assets/products/spona-klip-do-vlasu-ruzovy.jpeg";
import SkripceCerny from "../assets/products/spona-klip-do-vlasu-cerny.jpeg";
import StetecNaBarveni from "../assets/products/stetec-na-barveni-jednoduchy.jpeg";
import Aplikator from "../assets/products/aplikator.jpeg";
import DozaNaBarvy from "../assets/products/doza-na-barvy.png";
import DozaNaBarvyPapirova from "../assets/products/tubus.jpeg";
import CepiceRukavice from "../assets/products/cepice-na-barvenirukavice.jpeg";
import KartacBukovy from "../assets/products/kartac-z-bukoveho-dreva.png";
import HrebenMaly from "../assets/products/hreben-dreveny-maly.jpeg";
import HrebenVelky from "../assets/products/hreben-dreveny-vetsi.jpeg";
import CosmetikabioBarva from "../assets/products/CosmetikabioBarva.jpeg";

export interface Product {
    id: string;
    name: string;
    image: string;
    link: string;
}

export const productsMap: Record<string, Product> = {
    product1: {
        id: "product1",
        name: "Šampon na objem",
        image: SamponNaObjem,
        link: "https://www.haaro-naturo.cz/sampon-na-objem-a/",
    },
    product2: {
        id: "product2",
        name: "Šampon na uhlazení",
        image: SamponNaUhlazeni,
        link: "https://www.haaro-naturo.cz/sampon-na-uhlazeni-50g/",
    },
    product3: {
        id: "product3",
        name: "Šampon na mastné vlasy",
        image: SamponNaMastneVlasy,
        link: "https://www.haaro-naturo.cz/sampon-na-mastne-vlasy/",
    },
    product4: {
        id: "product4",
        name: "Šampon konopný",
        image: SamponKonopny,
        link: "https://www.haaro-naturo.cz/konopny-sampon/",
    },
    product5: {
        id: "product5",
        name: "Šampon na lupy",
        image: SamponNaLupy,
        link: "https://www.haaro-naturo.cz/sampon-na-lupy/",
    },
    product6: {
        id: "product6",
        name: "Šampon měsíčkový",
        image: SamponMesickovy,
        link: "https://www.haaro-naturo.cz/sampon-mesickovy/",
    },
    product7: {
        id: "product7",
        name: "Šampon kávový",
        image: SamponKavovy,
        link: "https://www.haaro-naturo.cz/kava-sampon/",
    },
    product8: {
        id: "product8",
        name: "Šampon před barvením",
        image: SamponPredBarvenim,
        link: "https://www.haaro-naturo.cz/sampon-pred-barvenim/",
    },
    product9: {
        id: "product9",
        name: "Antistatický a hydratační sprej",
        image: AntistatickySprej,
        link: "https://www.haaro-naturo.cz/antistatic-sprej/",
    },
    product10: {
        id: "product10",
        name: "Leave-in kondicionér",
        image: LeaveInKondicioner,
        link: "https://www.haaro-naturo.cz/leave-in-kondicioner/",
    },
    product11: {
        id: "product11",
        name: "Suchý vlasový olej",
        image: SuchyVlasovyOlej,
        link: "https://www.haaro-naturo.cz/suchy-olej-na-vlasy/",
    },
    product12: {
        id: "product12",
        name: "Aloe vera gel",
        image: AloeVeraGel,
        link: "https://www.haaro-naturo.cz/bio-aloe-vera-gel/",
    },
    product13: {
        id: "product13",
        name: "Lehký kondicionér na jemné vlasy",
        image: LehkyKondicioner,
        link: "https://www.haaro-naturo.cz/lehky-kondicioner/",
    },
    product14: {
        id: "product14",
        name: "Kondicionér na jemné vlasy - tuhý",
        image: TuhyKondicionerNaJemneVlasy,
        link: "https://www.haaro-naturo.cz/kondicioner-na-jemne-vlasy/",
    },
    product15: {
        id: "product15",
        name: "Kondicionér pro silné vlasy",
        image: VyzivujiciKondicioner,
        link: "https://www.haaro-naturo.cz/vyzivujici-kondicioner-pro-silne-vlasy/",
    },
    product16: {
        id: "product16",
        name: "Kondicionér na suché a silné vlasy - tuhý",
        image: TuhyKondicionerProSucheASilneVlasy,
        link: "https://www.haaro-naturo.cz/kondicioner-na-silne-vlasy/",
    },
    product17: {
        id: "product17",
        name: "Kávový kondicionér",
        image: KavovyKondicioner,
        link: "https://www.haaro-naturo.cz/kondicioner-kava/",
    },
    product18: {
        id: "product18",
        name: "Suchý šampon - blond",
        image: SuchySamponBlond,
        link: "https://www.haaro-naturo.cz/suchy-sampon-blond/",
    },
    product19: {
        id: "product19",
        name: "Suchý šampon - hnědý",
        image: SuchySamponHnedy,
        link: "https://www.haaro-naturo.cz/suchy-sampon-hnedy/",
    },
    product20: {
        id: "product20",
        name: "Suchý šampon - měděný",
        image: SuchySamponMedeny,
        link: "https://www.haaro-naturo.cz/suchy-sampon-medeny/",
    },
    product21: {
        id: "product21",
        name: "Suchý šampon - tmavě hnědý",
        image: SuchySamponTmaveHnedy,
        link: "https://www.haaro-naturo.cz/suchy-sampon-tmave-hnedy/",
    },
    product22: {
        id: "product22",
        name: "Náplň suchý šampon - blond",
        image: NaplnSuchySamponBlond,
        link: "https://www.haaro-naturo.cz/nahradni-napln-suchy-sampon-blond/",
    },
    product23: {
        id: "product23",
        name: "Náplň suchý šampon - hnědý",
        image: NaplnSuchySamponHnedy,
        link: "https://www.haaro-naturo.cz/nahradni-napln-suchy-sampon-hnedy/",
    },
    product24: {
        id: "product24",
        name: "Náplň suchý šampon - měděný",
        image: NaplnSuchySamponMedeny,
        link: "https://www.haaro-naturo.cz/nahradni-napln-suchy-sampon-medeny/",
    },
    product25: {
        id: "product25",
        name: "Náplň suchý šampon - tmavě hnědý",
        image: NaplnSuchySamponTmaveHnedy,
        link: "https://www.haaro-naturo.cz/nahradni-napln-suchy-sampon-tmave-hnedy/",
    },
    product26: {
        id: "product26",
        name: "Náplň suchý šampon - všechny barvy",
        image: NaplnSuchySamponVsechnyBarvy,
        link: "https://www.haaro-naturo.cz/nahradni-napln-suchy-sampon-vsechny-barvy/",
    },
    product27: {
        id: "product27",
        name: "Přírodní barva na míru",
        image: BarvaNaMiru,
        link: "https://www.haaro-naturo.cz/barva-na-miru/",
    },
    product28: {
        id: "product28",
        name: "Konzultace - přírodní barvení vlasů",
        image: KonzultaceBarvaNaVlasy,
        link: "https://www.haaro-naturo.cz/konzultace-prirodni-barveni-vlasu-2/",
    },
    product29: {
        id: "product29",
        name: "Vzorek barvy",
        image: VzorekBarvy,
        link: "https://www.haaro-naturo.cz/vzorek-barvy/",
    },
    product30: {
        id: "product30",
        name: "Mydlenka borovicová",
        image: MydlenkaBorovicova,
        link: "https://www.haaro-naturo.cz/mydlenka-borovicova/",
    },
    product31: {
        id: "product31",
        name: "Mydlenka keramická",
        image: MydlenkaKeramicka,
        link: "https://www.haaro-naturo.cz/mydlenka-keramicka/",
    },
    product32: {
        id: "product32",
        name: "Sítka na mýdlo",
        image: SitkaNaMydlo,
        link: "https://www.haaro-naturo.cz/sitka-na-mydlo/",
    },
    product33: {
        id: "product33",
        name: "Workshopy",
        image: Workshopy,
        link: "https://www.haaro-edu.cz/",
    },

/* KHADI BARVY */

    product34: {
        id: "product34",
        name: "Cassia Khadi",
        image: CassiaKhadi,
        link: "https://www.haaro-naturo.cz/cassia/",
    },
    product35: {
        id: "product35",
        name: "Zlatý přeliv Khadi",
        image: ZlatyPrelivKhadi,
        link: "https://www.haaro-naturo.cz/zlaty-preliv/",
    },
    product36: {
        id: "product36",
        name: "Světle blond Khadi",
        image: SvetleBlondKhadi,
        link: "https://www.haaro-naturo.cz/svetle-blond/",
    },
    product37: {
        id: "product37",
        name: "Středně blond Khadi",
        image: SredneBlondKhadi,
        link: "https://www.haaro-naturo.cz/stredne-blond/",
    },
    product38: {
        id: "product38",
        name: "Sluneční blond Khadi",
        image: SlunecniBlondKhadi,
        link: "https://www.haaro-naturo.cz/slunecni-blond/",
    },
    product39: {
        id: "product39",
        name: "Měděná Khadi",
        image: MedenaKhadi,
        link: "https://www.haaro-naturo.cz/medena/",
    },
    product40: {
        id: "product40",
        name: "Henna-amla-jatropha Khadi",
        image: HennaAmlaJatrophaKhadi,
        link: "https://www.haaro-naturo.cz/henna-amla-jatropha/",
    },
    product41: {
        id: "product41",
        name: "Henna Khadi",
        image: HennaKhadi,
        link: "https://www.haaro-naturo.cz/henna-khadi/",
    },
    product42: {
        id: "product42",
        name: "Tmava blond Khadi",
        image: TmavaBlondKhadi,
        link: "https://www.haaro-naturo.cz/tmava-blond/",
    },
    product43: {
        id: "product43",
        name: "Zlatá hnědá Khadi",
        image: ZlataHnedaKhadi,
        link: "https://www.haaro-naturo.cz/zlata-hneda/",
    },
    product44: {
        id: "product44",
        name: "Světle hnědá Khadi",
        image: SvetleHnedaKhadi,
        link: "https://www.haaro-naturo.cz/svetle-hneda/",
    },
    product45: {
        id: "product45",
        name: "Popelavě hnědá Khadi",
        image: PopelaveHnedaKhadi,
        link: "https://www.haaro-naturo.cz/popelave-hneda/",
    },
    product46: {
        id: "product46",
        name: "Oříškově hnědá Khadi",
        image: OriskoveHnedaKhadi,
        link: "https://www.haaro-naturo.cz/oriskove-hneda/",
    },
    product47: {
        id: "product47",
        name: "Střední hnědá Khadi",
        image: StredniHnedaKhadi,
        link: "https://www.haaro-naturo.cz/stredni-hneda/",
    },
    product48: {
        id: "product48",
        name: "Tmavě hnědá Khadi",
        image: TmaveHnedaKhadi,
        link: "https://www.haaro-naturo.cz/tmave-hneda/",
    },
    product49: {
        id: "product49",
        name: "Černá Khadi",
        image: CernaKhadi,
        link: "https://www.haaro-naturo.cz/cerna/",
    },
    product50: {
        id: "product50",
        name: "Indigo Khadi",
        image: IndigoKhadi,
        link: "https://www.haaro-naturo.cz/indigo/",
    },

/* KHADI OLEJE */

    product51: {
        id: "product51",
        name: "Vlasové tonikum - stylingový sprej",
        image: VlasoveTonikumKhadi,
        link: "https://www.haaro-naturo.cz/vlasove-tonikum-stylingovy-sprej/",
    },
    product52: {
        id: "product52",
        name: "Vlasový olej Amla",
        image: OlejNaVlasyAlmaKhadi,
        link: "https://www.haaro-naturo.cz/vlasocy-olej-amla/",
    },
    product53: {
        id: "product53",
        name: "Vlasový olej Color care",
        image: OlejNaVlasyColorCareKhadi,
        link: "https://www.haaro-naturo.cz/vlasovy-olej-color-care-50ml/",
    },
    product54: {
        id: "product54",
        name: "Vlasový olej Neem harmonie",
        image: OlejNaVlasyNeemKhadi,
        link: "https://www.haaro-naturo.cz/vlasovy-olej-balzam/",
    },
    product55: {
        id: "product55",
        name: "Vlasový olej Růže reparační",
        image: OlejNaVlasyRuzeKhadi,
        link: "https://www.haaro-naturo.cz/vlasovy-olej-ruze-reparacni-50ml/",
    },
    product56: {
        id: "product56",
        name: "Vlasový olej Vitalita",
        image: OlejNaVlasyVitalitaKhadi,
        link: "https://www.haaro-naturo.cz/vlasovy-olej-vitalita/",
    },

    /* DALŠÍ */

    product57: {
        id: "product57",
        name: "Gel na vlasy - Oliebe",
        image: GelNaVlasyOliebe,
        link: "https://www.haaro-naturo.cz/oliebe-gel/",
    },

    /* PÉČE */

    product58: {
        id: "product58",
        name: "Alma - Khadi",
        image: AlmaKhadi,
        link: "https://www.haaro-naturo.cz/amla/",
    },
    product59: {
        id: "product59",
        name: "Hloubkové čištění aktivním uhlím - Khadi",
        image: CisteniUhlimKhadi,
        link: "https://www.haaro-naturo.cz/hloubkove-cisteni-aktivnim-uhlim/",
    },
    product60: {
        id: "product60",
        name: "Shikakai - Khadi",
        image: ShikakaiKhadi,
        link: "https://www.haaro-naturo.cz/shikakai/",
    },
    product61: {
        id: "product61",
        name: "Směs bylin - Khadi",
        image: SmesBylinKhadi,
        link: "https://www.haaro-naturo.cz/smes-bylin/",
    },

    /* RUČNÍKY */

    product62: {
        id: "product62",
        name: "Ručník bio bavlna-bambus Béžový",
        image: RucnikBezovy,
        link: "https://www.haaro-naturo.cz/rucnik-bio-bavlna-bambus-4/",
    },
    product63: {
        id: "product63",
        name: "Ručník bio bavlna-bambus Tmavě modrý",
        image: RucnikModry,
        link: "https://www.haaro-naturo.cz/rucnik-bio-bavlna-bambus-3/",
    },
    product64: {
        id: "product64",
        name: "Ručník bio bavlna-bambus Šedý",
        image: RucnikSedy,
        link: "https://www.haaro-naturo.cz/rucnik-bio-bavlna-bambus-5/",
    },
    product65: {
        id: "product65",
        name: "Ručník bio bavlna-bambus Fialový",
        image: RucnikFialovy,
        link: "https://www.haaro-naturo.cz/rucnik-bio-bavlna-bambus/",
    },
    product66: {
        id: "product66",
        name: "Ručník bio bavlna-bambus Černý",
        image: RucnikCerny,
        link: "https://www.haaro-naturo.cz/rucnik-bio-bavlna-bambus-2/",
    },
    product67: {
        id: "product67",
        name: "Ručník bio bavlna-bambus Růžový",
        image: RucnikRuzovy,
        link: "https://www.haaro-naturo.cz/rucnik/",
    },

    /* SKŘIPCE */

    product68: {
        id: "product68",
        name: "Spona - klip do vlasů růžový",
        image: SkripceRuzovy,
        link: "https://www.haaro-naturo.cz/klip-do-vlasu-ruzovy/",
    },
    product69: {
        id: "product69",
        name: "Spona - klip do vlasů černý",
        image: SkripceCerny,
        link: "https://www.haaro-naturo.cz/sponka-do-vlasu/",
    },

    /* ŠTĚTCE a DALŠÍ */

    product70: {
        id: "product70",
        name: "Štětec na barvení - jednoduchý",
        image: StetecNaBarveni,
        link: "https://www.haaro-naturo.cz/stetec-na-barveni/",
    },
/*    product71: {
        id: "product71",
        name: "Štětec na barvení - kompostovatelný - růžový",
        image: StetecNaBarveniRuzovy,
        link: "https://www.haaro-naturo.cz/stetec-na-barveni-kompostovatelny/",
    },
    product72: {
        id: "product72",
        name: "Štětec na barvení - kompostovatelný - modrý",
        image: StetecNaBarveniModrý,
        link: "https://www.haaro-naturo.cz/stetec-na-barveni-kompostovatelny-modry/",
    },*/
    product73: {
        id: "product73",
        name: "Aplikátor",
        image: Aplikator,
        link: "https://www.haaro-naturo.cz/aplikator/",
    },
    product74: {
        id: "product74",
        name: "Dóza na barvy",
        image: DozaNaBarvy,
        link: "https://www.haaro-naturo.cz/doza-na-barvy/",
    },
    product75: {
        id: "product75",
        name: "Papírový tubus na barvu nebo byliny",
        image: DozaNaBarvyPapirova,
        link: "https://www.haaro-naturo.cz/papirovy-tubus-na-barvu-nebo-byliny/",
    },
  /*  product76: {
        id: "product76",
        name: "Teploměr",
        image: Teplomer,
        link: "https://www.haaro-naturo.cz/teplomer/",
    },*/
    product77: {
        id: "product77",
        name: "Čepice na barvení + rukavice",
        image: CepiceRukavice,
        link: "https://www.haaro-naturo.cz/cepice-na-barveni-rukavice/",
    },
   /* product78: {
        id: "product78",
        name: "Spona-klip do vlasů růžový KOMPOSTOVATELNÝ",
        image: KompostovatelnaSponaRuzova,
        link: "https://www.haaro-naturo.cz/spona-klip-do-vlasu-ruzovy-kompostovatelny/",
    },
    product79: {
        id: "product79",
        name: "Spona-klip do vlasů modrá KOMPOSTOVATELNÝ",
        image: KompostovatelnaSponaModra,
        link: "https://www.haaro-naturo.cz/spona-klip-do-vlasu-ruzovy-kompostovatelny-2/",
    },*/
    product80: {
        id: "product80",
        name: "Kartáč z bukového dřeva",
        image: KartacBukovy,
        link: "https://www.haaro-naturo.cz/kartac-z-bukoveho-dreva-2/",
    },
    product81: {
        id: "product81",
        name: "Hřeben dřevěný malý",
        image: HrebenMaly,
        link: "https://www.haaro-naturo.cz/hreben-dreveny-mensi/",
    },
    product82: {
        id: "product82",
        name: "Hřeben dřevěný velký",
        image: HrebenVelky,
        link: "https://www.haaro-naturo.cz/hreben-dreveny-vetsi/",
    },

/* COSMETIKABIO BARVY */

    product83: {
        id: "product83",
        name: "Cassia Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/cassia-2/",
    },
    product84: {
        id: "product84",
        name: "Popelavá blond Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/popelava-blond/",
    },
    product85: {
        id: "product85",
        name: "Pšeničná blond Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/psenicna-blond/",
    },
    product86: {
        id: "product86",
        name: "Jahodová blond Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/jahodova-blond/",
    },
    product87: {
        id: "product87",
        name: "Henna Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/henna/",
    },
    product88: {
        id: "product88",
        name: "Kaštanově červená Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/kastanove-cervena/",
    },
    product89: {
        id: "product89",
        name: "Kaštanová Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/kastanova/",
    },
    product90: {
        id: "product90",
        name: "Vínově červená Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/vinove-cervena/",
    },
    product91: {
        id: "product91",
        name: "Zlatá blond Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/zlata-blond/",
    },
    product92: {
        id: "product92",
        name: "Měděná hnědá Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/medena-hneda/",
    },
    product93: {
        id: "product93",
        name: "Hnědá Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/hneda/",
    },
    product94: {
        id: "product94",
        name: "Mahagon Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/mahagon/",
    },
    product95: {
        id: "product95",
        name: "Tmavě hnědá Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/tmave-hneda-2/",
    },
    product96: {
        id: "product96",
        name: "Černá Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/cerna-2/",
    },
    product97: {
        id: "product97",
        name: "Fialová Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/fialova-cosmetikabio/",
    },
    product98: {
        id: "product98",
        name: "Indigo Cosmetikabio",
        image: CosmetikabioBarva,
        link: "https://www.haaro-naturo.cz/indigo-2/",
    },
};
