import React, { useEffect, useState} from 'react';

import "../App.css";
import "../styles/Homepage.css";
import {db} from "../firebase";
import {collection, doc, onSnapshot, deleteDoc} from 'firebase/firestore';
import BlogSection from "../components/BlogSection";
import {Spinner} from "../components/Spinner";
import {toast} from "react-toastify";

interface Blog {
    id: string;
    imgUrl: string;
    title: string;
    description: string;
    category: string[];
    timestamp: any;
}

interface HomepageProps {
    user: any,
}

const Homepage: React.FC<HomepageProps> = (user) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [allCategories, setAllCategories] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(''); // New state for search query

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(db, "blogs"),
            (snapshot) => {
                const blogsList: Blog[] = snapshot.docs.map((doc) => ({
                    ...doc.data() as Blog,
                    id: doc.id,
                }));

                blogsList.sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis());

                const categories = Array.from(new Set(blogsList.flatMap(blog => blog.category)));
                setAllCategories(categories)

                setBlogs(blogsList);
                setLoading(false);
            }, (error) => {
                console.error("Chyba při získávání dat:", error);
            }
        );
        return () => {
            unsubscribe();
        }
    }, []);

    const filteredBlogs = blogs.filter(blog =>
        (selectedCategories.length === 0 || blog.category.some(cat => selectedCategories.includes(cat))) &&
        (blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            blog.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            blog.category.some(cat => cat.toLowerCase().includes(searchQuery.toLowerCase())))
    );

    const handleCategorySelection = (category: string) => {
        setSelectedCategories(prev =>
            prev.includes(category) ? prev.filter(cat => cat !== category) : [...prev, category]
        );
    };

    if (loading) {
        return <Spinner />;
    }

    const handleDelete = async (id: string): Promise<void> => {
        if (user && window.confirm("Opravdu chcete smazat tento blog?")) {
            try {
                setLoading(true);
                await deleteDoc(doc(db, "blogs", id));
                toast.success("Soubor byl úspěšně smazán.");
                setLoading(false);
            } catch (error) {
                console.error("Chyba při mazání dokumentu:", error);
            }
        }
    };

    return (
        <div className="Homepage">
            <h1>Blog</h1>
            <div className="category-selector">
                {allCategories.map(category => (
                    <div key={category}>
                        <input
                            id={`category-${category}`}
                            type="checkbox"
                            checked={selectedCategories.includes(category)}
                            onChange={() => handleCategorySelection(category)}
                        />
                        <label htmlFor={`category-${category}`}>
                            {category}
                        </label>
                    </div>
                ))}
            </div>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="hledat"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div>
                <BlogSection blogs={filteredBlogs} user={user} handleDelete={handleDelete}/>
            </div>
        </div>
    );
}

export default Homepage;
