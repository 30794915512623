import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { User } from 'firebase/auth'; // nebo váš vlastní typ uživatele

interface ProtectedRouteProps {
    children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    // Předpokládá, že 'user' v localStorage je serializovaný objekt typu 'User'
    const user = JSON.parse(localStorage.getItem('user') || 'null') as User | null;

    if (!user) {
        return <Navigate to="/login" />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
