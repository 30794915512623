import React from 'react';
import {Product} from "./Products";
import "../styles/DetailPage.css";
import {Link} from "react-router-dom";

interface ProductCardProps {
    product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
    return (
        <Link to={product.link} className="product-card">
            <div>
                <img src={product.image} alt={product.name}/>
                <h3>{product.name}</h3>
            </div>
        </Link>
    );
};

export default ProductCard;
