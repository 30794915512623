import React, {useEffect, useState} from 'react';

import "../App.css";
import "../styles/DetailPage.css";
import "../styles/Homepage.css";
import {useLocation, useNavigate} from "react-router-dom";
import {db} from "../firebase";
import { doc, getDoc} from 'firebase/firestore';
import ProductCard from '../components/ProductCard';
import {productsMap} from "../components/Products";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Spinner} from "../components/Spinner";

interface Blog {
    id: string;
    title: string;
    imgUrl: string;
    category: string[];
    description: string;
    content: string;
    relatedProducts: string[];
}

const DetailPage: React.FC = () => {
    const [blog, setBlog] = useState<Blog | null>(null);

    const location = useLocation();
    const navigate = useNavigate();
    const { id } = location.state as { id: string } || {};
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (id) {
            getBlogDetail(id).then(r => console.log("Detail blogu:", r));
        } else {
            console.log("Chybějící ID blogu");
            navigate('/');
        }
    }, [id, navigate]);

    const getBlogDetail = async (id: string) => {
        const docRef = doc(db, "blogs", id);
        const blogDetail = await getDoc(docRef);
        if (blogDetail.exists()) {
            const blogData = blogDetail.data() as Blog;
            setBlog(blogData);
            setSelectedProducts(blogData.relatedProducts || []);
            setLoading(false);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    const CustomPrevArrow = (props: { className?: string; style?: React.CSSProperties; onClick?: () => void }) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'block' }}
                onClick={onClick}
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 5L10 10L15 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        );
    };

    const CustomNextArrow = (props: { className?: string; style?: React.CSSProperties; onClick?: () => void }) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'block' }}
                onClick={onClick}
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 5L10 10L5 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 930,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <div className="main-container">
            <div className="detail-container">
                <h1>{blog?.title}</h1>
                <p className={"category-item"}>
                    {blog?.category && blog?.category.length > 1
                        ? blog?.category.map((cat, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && ' '}
                                <span className="category-span">{cat}</span>
                            </React.Fragment>
                        ))
                        : 'Žádné kategorie'
                    }
                </p>
                <div className={"detail-perex"} dangerouslySetInnerHTML={{__html: blog?.description || ""}}></div>
                <img src={blog?.imgUrl} alt={blog?.title}/>
                <div className="detail-content" dangerouslySetInnerHTML={{__html: blog?.content || ""}}></div>
                {
                    selectedProducts.length > 0 && (
                        <section className="related-products">
                            <h2>Související produkty</h2>
                            <Slider {...settings}>
                                {selectedProducts.map(productId => {
                                    const product = productsMap[productId];
                                    return product ? <div key={product.id}><ProductCard product={product}/></div> : null;
                                })}
                            </Slider>
                        </section>
                    )
                }
                <div className="newsletter-container">
                    <h2>Zaujal vás článek? Chcete dostat echo, až vyjde nový? </h2>
                    <p>Stačí zadat e-mail a přihlásíte se k odběru newsletteru</p>
                    <form action="https://haaro.ecomailapp.cz/public/subscribe/1/43c2cd496486bcc27217c3e790fb4088"
                          method="post">
                        <input type="email" name="email" placeholder="Váš email" required/>
                        <button type="submit">přihlásit se</button>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default DetailPage;
