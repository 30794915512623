import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import "../App.css";
import "../styles/AddEditBlog.css";
import {db, storage} from "../firebase";
import {ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";
import {addDoc, collection, doc, getDoc, serverTimestamp, updateDoc} from "firebase/firestore";
import {useNavigate, useParams} from "react-router-dom";
import {User} from "firebase/auth";
import {toast} from "react-toastify";
// @ts-ignore
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {productsMap} from "../components/Products";
import {Spinner} from "../components/Spinner";

interface BlogForm {
    title: string;
    description: string;
    content: string;
    category: Array<string>;
    imgUrl: string;
    image: File | null;
    imagePreview: string | null;
}

const initialState: BlogForm = {
    title: "",
    description: "",
    content: "",
    category: [],
    imgUrl: "",
    image: null,
    imagePreview: null,
};

const categoryOptions = [
    "Přírodní barvy na vlasy",
    "Péče o vlasy",
    "Šediny",
    "Kadeřnické služby",
    "Návody",
    "Recenze",
    "Mytí vlasů",
    "Styling"
];

interface AddEditBlogProps {
    user: User | null;
}

function AddEditBlog({ user }: AddEditBlogProps) {
    const [form, setForm] = useState<BlogForm>(initialState);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [newCategory, setNewCategory] = useState<string>("");
    const [availableCategories, setAvailableCategories] = useState<string[]>(categoryOptions);
    const [file, setFile] = useState<File | null>(null);
    const [progress, setProgress] = useState<number>(0);
    const navigate = useNavigate();
    const { id } = useParams<{ id?: string }>();
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const prevIdRef = useRef<string | undefined>();

    useEffect(() => {
        const fetchBlogDetail = async () => {
            if (!id) {
                setIsDataLoaded(true);
                return;
            }

            const docRef = doc(db, "blogs", id);
            const snapshot = await getDoc(docRef);

            if (snapshot.exists()) {
                const data = snapshot.data() as BlogForm;
                setForm({
                    title: data.title || "",
                    description: data.description || "",
                    content: data.content || "",
                    category: data.category || [],
                    imgUrl: data.imgUrl || "",
                    image: null,
                    imagePreview: data.imgUrl || null,
                });

                const relatedProducts = snapshot.data().relatedProducts as string[];
                setSelectedProducts(relatedProducts || []);
                setIsDataLoaded(true);
            } else {
                toast.error("Blog nenalezen");
                navigate("/");
            }
        };

        if (!isDataLoaded) {
            fetchBlogDetail();
        }
    }, [id, db, navigate, isDataLoaded]);

    useEffect(() => {
        const uploadFile = () => {
            if (file) {
                const filePath = `uploads/${new Date().getTime()}_${file.name}`;
                const storageRef = ref(storage, filePath);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on("state_changed", (snapshot) => {
                        const progress = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setProgress(progress);
                    }, (error) => {
                        console.error("Chyba při nahrávání souboru:", error);
                    }, () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log("File available at", downloadURL);
                            toast.info("Soubor byl úspěšně nahrán.");
                            setForm({ ...form, imgUrl: downloadURL });
                        });
                    }
                );
            }
        };
        if (file) uploadFile();
    }, [file]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleCategoryChange = (category: string) => {
        if (form.category.includes(category)) {
            setForm({
                ...form,
                category: form.category.filter(cat => cat !== category),
            });
        } else {
            setForm({
                ...form,
                category: [...form.category, category],
            });
        }
    };

    const handleNewCategoryChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setNewCategory(e.target.value);
    };

    const addNewCategory = () => {
        if (newCategory && !availableCategories.includes(newCategory)) {
            setAvailableCategories([...availableCategories, newCategory]);
            setNewCategory("");
        }
    };

    const handleProductChange = (productId: string) => {
        setSelectedProducts(prevSelectedProducts =>
            prevSelectedProducts.includes(productId)
                ? prevSelectedProducts.filter(id => id !== productId)
                : [...prevSelectedProducts, productId]
        );
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (form.title && form.description && form.content && form.category && selectedProducts) {
            if(!id){
                try {
                    await addDoc(collection(db, "blogs"), {
                        ...form,
                        relatedProducts: selectedProducts,
                        timestamp: serverTimestamp(),
                    });
                    console.log("Blog byl úspěšně přidán.");
                    toast.success("Blog byl úspěšně přidán.");
                } catch (error) {
                    console.error("Chyba při přidávání blogu:", error);
                }
            } else {
                try {
                    await updateDoc(doc(db, "blogs", id), {
                        ...form,
                        relatedProducts: selectedProducts,
                        timestamp: serverTimestamp(),
                    });
                    console.log("Blog byl úspěšně aktualizován.");
                    toast.success("Blog byl úspěšně aktualizován.");
                } catch (error) {
                    console.error("Chyba při přidávání blogu:", error);
                }
            }
        } else {
            console.error("Některé z polí nejsou vyplněné.");
            toast.error("Některé z polí nejsou vyplněné.");
        }
        navigate("/");
    };

    return (
    isDataLoaded ? (
            <div className="main-container">
                <h1>{id ? "Upravit blog" : "Vytvořit nový blog"}</h1>
                <div>
                    <form action="" onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="text"
                                className="input-field"
                                placeholder="Název"
                                name="title"
                                value={form.title}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <div className={"categories-container"}>
                                {availableCategories.map((category) => (
                                    <div className={"label-container"} key={category}>
                                        <input
                                            type="checkbox"
                                            id={category}
                                            name={category}
                                            checked={form.category.includes(category)}
                                            onChange={() => handleCategoryChange(category)}
                                        />
                                        <label htmlFor={category}>{category}</label>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    value={newCategory}
                                    onChange={handleNewCategoryChange}
                                    placeholder="Nová ketgorie"
                                />
                                <button type="button" onClick={addNewCategory}>
                                    Přidat kategorii
                                </button>
                            </div>
                        </div>
                        <div className={"ReactQuill"}>
                            <ReactQuill
                                placeholder="Perex"
                                value={form.description}
                                onChange={(description: any) => setForm({...form, description})}
                                modules={AddEditBlog.modules}
                                formats={AddEditBlog.formats}
                            />
                        </div>
                        <div className={"input-container"}>
                            <img
                                src={form.imgUrl}
                                alt="{form.title}"
                                className="img-preview"
                            />
                            <input
                                type="file"
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className={"ReactQuill"}>
                            <ReactQuill
                                value={form.content}
                                onChange={(content: any) => setForm({...form, content})}
                                modules={AddEditBlog.modules}
                                formats={AddEditBlog.formats}
                                placeholder="Text blogu"
                            />
                        </div>
                        <div className="product-selection categories-container">
                            <h3>Související produkty: </h3>
                            {Object.values(productsMap).map((product) => (
                                <div className={"label-container"} key={product.id}>
                                    <input
                                        type="checkbox"
                                        id={product.id}
                                        checked={selectedProducts.includes(product.id)}
                                        onChange={() => handleProductChange(product.id)}
                                    />
                                    <label htmlFor={product.id}>{product.name}</label>
                                </div>
                            ))}
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="submit-btn"
                                disabled={id === null && progress !== null && progress < 100}>
                                Uložit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        ) : (
            <Spinner />
        )
    );
}

AddEditBlog.modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],

        [{'header': [2, 3]}],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],
        [{'indent': '-1'}, {'indent': '+1'}],

        [{'align': []}],
        ['clean'],
        ['link', 'image', 'video'],
    ]
};

AddEditBlog.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'color', 'background', 'align',
    'code-block', 'script', 'sub', 'super', 'formula'
];

export default AddEditBlog;
