import React, {Component} from 'react';
import "../App.css";
import {Link} from "react-router-dom";

class NotFound extends Component {

    render() {
        return (
            <div className="main-container">
                <h1>Stránka nebyla nalezena...</h1>
                <Link to={"/"}>Zpět na úvodní stánku blogu</Link>
            </div>
        );
    }
}

export default NotFound;
