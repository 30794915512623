import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import "../styles/Footer.css";

const firebaseConfig = {
    apiKey: "AIzaSyA7W5_fTU_vRjv3YQCm2pdVKBFCOM5tjuQ",
    authDomain: "blogova-aplikace.firebaseapp.com",
    projectId: "blogova-aplikace",
    storageBucket: "blogova-aplikace.appspot.com",
    messagingSenderId: "1000024448629",
    appId: "1:1000024448629:web:0d92d4eb40868e424ee23c",
    measurementId: "G-632X77F0YP"
};

// Inicializace Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log('Přihlášení proběhlo úspěšně.');
            localStorage.setItem('user', JSON.stringify(userCredential.user));

            navigate('/');
        } catch (error) {
            setError('Přihlášení selhalo. Zkontrolujte přihlašovací údaje.');
        }
    };


    return (
        <div>
            <h1>Login page</h1>
            <p className={"text-center"}>Přihlášení do aplikace slouží výhradně pro správce blogu.</p>
            <form onSubmit={handleLogin}>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Heslo" required />
                <button type="submit" className="submit-btn">Přihlásit se</button>
            </form>
            {error && <p>{error}</p>}
        </div>
    );
};

export default Login;
